import React from 'react'

import Box from 'components/Box';
import Flex from 'components/Flex';
import Text from 'components/Text';
import Link from 'i18n/Link';

const Header = ({ siteTitle, ...props }) => (
  <Flex
    is="header"
    position="fixed"
    bg="primary"
    top={0}
    left={0}
    right={0}
    alignItems="center"
    zOrder={2}
    {...props}
  >
    <Box px="1em" flex={1}>
      <Text is="h1" fontSize="1.8em">
        <Link to="/" color="white">
          {siteTitle}
        </Link>
      </Text>
    </Box>
  </Flex>
)

export default Header
