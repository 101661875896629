import React from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import {
  typography,
  space,
  color,
  display,
  position,
  textStyle,
} from 'styled-system';
import tag from 'clean-tag';

import blacklist from './utils/blacklist';
import injectProps from './utils/injectProps';

const Text = styled(tag)`
  margin-top: 0;
  margin-bottom: 0;
  ${typography}
  ${display}
  ${space}
  ${color}
  ${position}
  ${injectProps('whiteSpace')}
  ${injectProps('textTransform')}
  ${textStyle}
  ${({ onClick }) => onClick && 'cursor: pointer;'}
`;

Text.defaultProps = {
  is: 'p',
  fontSize: '1em',
  // lineHeight: 1.5,
  blacklist,
};

Text.inline = (props) => <Text is="span" {...props} />;
Text.bold = (props) => <Text fontWeight="bold" {...props} />;
Text.p = (props) => <Text textStyle="p" {...props} />;

range(6).forEach((key) => {
  const i = key + 1;
  const h = `h${i}`;
  Text[h] = (props) => (
    <Text
      is={h}
      // textStyle={h}
      {...props}
    />
  );
});

Text.displayName = 'Text';

export default Text;
